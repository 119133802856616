// ./src/hooks/useSearchPlaces.js
/* global AMap */
import { useState } from 'react';
import styles from './SearchPlaces.module.css';

const useSearchPlaces = (map) => {
    const [searchMarker, setSearchMarker] = useState(null); // 存储搜索结果标记
    const [infoWindow, setInfoWindow] = useState(null);

    const ZOOMLEVEL = 16;

    const searchPlace = async (keyword, zoomLevel = ZOOMLEVEL) => {
        if (!map || !keyword) return;

        const apiKey = 'a31e84a5f5d5a1d1c17038da59390737';
        const url = `https://restapi.amap.com/v3/place/text?key=${apiKey}&keywords=${keyword}&output=json`;

        try {
            const response = await fetch(url);
            const data = await response.json();

            if (data.status === '1' && data.count > 0) {
                const pois = data.pois;
                const firstLocation = pois[0].location.split(',');
                const lng = parseFloat(firstLocation[0]);
                const lat = parseFloat(firstLocation[1]);

                map.setCenter([lng, lat]);
                map.setZoom(zoomLevel);

                // 清除之前的搜索标记
                if (searchMarker) {
                    searchMarker.setMap(null);
                }
                if (infoWindow) {
                    infoWindow.close();
                }

                // 添加新的搜索结果标记
                const marker = new AMap.Marker({
                    position: [lng, lat],
                    map: map,
                });

                const newInfoWindow = new AMap.InfoWindow({
                    content: `
                        <div class="${styles.infoWindow}">
                            <div class="${styles.infoWindowName}">${pois[0].name}</div>
                            <div class="${styles.infoWindowAddress}">${pois[0].address}</div>
                        </div>
                    `,
                    offset: new AMap.Pixel(0, -30),
                    maxWidth: 200,
                });
                newInfoWindow.open(map, [lng, lat]);

                setSearchMarker(marker);
                setInfoWindow(newInfoWindow);

                // 添加点击标记时显示信息窗口
                marker.on('click', () => {
                    newInfoWindow.open(map, marker.getPosition());
                });

                // 监听信息窗口关闭事件，关闭时移除标记
                AMap.event.addListener(newInfoWindow, 'close', () => {
                    marker.setMap(null);  // 移除标记
                });

            } else {
                alert('未找到相关地点');
            }
        } catch (error) {
            console.error('Search API error:', error);
        }
    };

    return { searchPlace };
};

export default useSearchPlaces;
