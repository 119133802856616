import React from 'react';
import { Link } from 'react-router-dom';
import './ProductPage.css';

const LiDAR = () => {
  return (
    <div className="product-page">
      <header className="product-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>LiDAR扫描系统</h1>
      </header>

      <main className="product-content">
        <section className="product-hero">
          <img src={`${process.env.PUBLIC_URL}/images/lidar-system-large.jpg`} alt="LiDAR系统" />
          <div className="product-intro">
            <h2>高精度3D扫描方案</h2>
            <p>基于Apple最新LiDAR扫描器，实现精确的3D建模和空间数据采集，支持实时AR场景叠加。</p>
          </div>
        </section>

        <section className="product-features">
          <h2>系统特点</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>高精度扫描</h3>
              <p>采用Apple最新LiDAR扫描器，提供毫米级扫描精度。</p>
            </div>
            <div className="feature-item">
              <h3>实时建模</h3>
              <p>支持实时3D建模，现场即可查看扫描成果。</p>
            </div>
            <div className="feature-item">
              <h3>AR叠加</h3>
              <p>支持AR实景叠加，直观展示设计与实际的差异。</p>
            </div>
          </div>
        </section>

        <section className="hardware-requirements">
          <h2>硬件要求</h2>
          <div className="requirements-content">
            <ul>
              <li>iPhone 12 Pro/ProMax及以上机型</li>
              <li>最低6GB RAM</li>
              <li>10GB以上可用存储空间</li>
              <li>iOS 16.0或更高版本</li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LiDAR; 