import React, { useState, useEffect } from 'react';
import { message, Table, Form, Input, Button, Select, Modal } from 'antd';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';
import './AdminPanel.css';

const { Option } = Select;

const AdminPanel = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            message.error('请先登录');
            navigate('/login');
            return;
        }
        fetchUsers();
    }, [navigate]);

    // 获取用户列表
    const fetchUsers = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/auth/users`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (response.ok) {
                const data = await response.json();
                setUsers(data);
            } else {
                message.error('获取用户列表失败');
            }
        } catch (error) {
            message.error('获取用户列表失败');
        } finally {
            setLoading(false);
        }
    };

    // 创建用户
    const createUser = async (values) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/auth/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(values)
            });
            const data = await response.json();
            if (response.ok) {
                message.success('用户创建成功');
                form.resetFields();
                fetchUsers();
            } else {
                message.error(data.message || '创建用户失败');
            }
        } catch (error) {
            message.error('创建用户失败');
        } finally {
            setLoading(false);
        }
    };

    // 删除用户
    const deleteUser = async (userId) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_BASE_URL}/auth/users/${userId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const data = await response.json();
            if (response.ok) {
                message.success('用户删除成功');
                fetchUsers();
            } else {
                message.error(data.message || '删除用户失败');
            }
        } catch (error) {
            message.error('删除用户失败');
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: '用户名',
            dataIndex: 'username',
        },
        {
            title: '角色',
            dataIndex: 'role',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Button 
                    danger 
                    onClick={() => {
                        Modal.confirm({
                            title: '确认删除',
                            content: `确定要删除用户 ${record.username} 吗？`,
                            onOk: () => deleteUser(record.id)
                        });
                    }}
                >
                    <DeleteOutlined />
                </Button>
            ),
        },
    ];

    return (
        <div className="admin-panel">
            <div className="admin-header">
                <h2>用户管理系统</h2>
                <Button 
                    type="primary" 
                    onClick={() => navigate('/dashboard')}
                >
                    返回主页
                </Button>
            </div>
            
            <div className="admin-content">
                {/* 创建用户表单 */}
                <div className="create-user-form">
                    <h3><UserAddOutlined /> 创建新用户</h3>
                    <Form
                        form={form}
                        onFinish={createUser}
                        layout="vertical"
                    >
                        <Form.Item
                            label="用户名"
                            name="username"
                            rules={[{ required: true, message: '请输入用户名' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="密码"
                            name="password"
                            rules={[{ required: true, message: '请输入密码' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="角色"
                            name="role"
                            initialValue="user"
                        >
                            <Select>
                                <Option value="user">普通用户</Option>
                                <Option value="admin">管理员</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button 
                                type="primary" 
                                htmlType="submit" 
                                icon={<UserAddOutlined />}
                                loading={loading}
                            >
                                创建用户
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {/* 用户列表 */}
                <div className="user-list">
                    <h3>用户列表</h3>
                    <Table
                        columns={columns}
                        dataSource={users}
                        rowKey="id"
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminPanel; 