// ./src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import MapContainer from './components/MapContainer';
import AdminPanel from './components/AdminPanel';
import PrivateRoute from './components/PrivateRoute';
import RTK from './pages/products/RTK';
import LiDAR from './pages/products/LiDAR';
import Modeling from './pages/products/Modeling';
import Monitoring from './pages/features/Monitoring';
import Support from './pages/features/Support';
import Security from './pages/features/Security';
import About from './pages/About';
import Standards from './pages/Standards';
import Contact from './pages/Contact';
import ThreeModelViewer from './components/ThreeModelViewer';


function App() {
    // const [glbPath, setGlbpath] = React.useState('/3D_data/model_v3/test_3d-3.glb');
    const [glbPath, setGlbpath] = React.useState('https://sitesight-test-1257543956.cos.ap-shanghai.myqcloud.com/test_3d_max.glb');
    //todo
    const changeModelPath = (newGlbPath) => {
      setGlbpath(newGlbPath);
    };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/map" element={ <PrivateRoute> <MapContainer changeModelPath={changeModelPath}/> </PrivateRoute> } />
        <Route path="/admin" element={ <PrivateRoute> <AdminPanel /> </PrivateRoute> } />
        <Route path="/products/rtk" element={<RTK />} />
        <Route path="/products/lidar" element={<LiDAR />} />
        <Route path="/products/modeling" element={<Modeling />} />
        <Route path="/features/monitoring" element={<Monitoring />} />
        <Route path="/features/support" element={<Support />} />
        <Route path="/features/security" element={<Security />} />
        <Route path="/about" element={<About />} />
        <Route path="/standards" element={<Standards />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/3d-model" element={<ThreeModelViewer glbPath={glbPath} changeModelPath={changeModelPath} />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;