import React from 'react';
import { Link } from 'react-router-dom';
import './Standards.css';

const Standards = () => {
  return (
    <div className="standards-page">
      <header className="standards-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>技术标准</h1>
      </header>

      <main className="standards-content">
        <section className="standard-intro">
          <h2>企业标准</h2>
          <p>Q/511421BRJ 002-2024</p>
          <p>上海巡智科技有限公司企业标准</p>
        </section>

        <section className="requirements">
          <h2>软件要求</h2>
          <div className="requirements-grid">
            <div className="requirement-item">
              <h3>硬件环境</h3>
              <ul>
                <li>多频率GNSS信号的便携式RTK</li>
                <li>iPhone 12 Pro/ProMax以上机型</li>
                <li>最低6GB RAM</li>
                <li>10GB以上存储空间</li>
              </ul>
            </div>
            <div className="requirement-item">
              <h3>软件环境</h3>
              <ul>
                <li>iOS 16.0或更高版本</li>
                <li>支持4G/5G网络</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="compliance">
          <h2>合规标准</h2>
          <ul>
            <li>符合GB/T 22239信息安全标准</li>
            <li>符合GB/T 28452软件安全标准</li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default Standards; 