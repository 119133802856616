import React from 'react';
import { Link } from 'react-router-dom';
import './ProductPage.css';

const RTK = () => {
  return (
    <div className="product-page">
      <header className="product-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>RTK实时定位系统</h1>
      </header>

      <main className="product-content">
        <section className="product-hero">
          <img src={`${process.env.PUBLIC_URL}/images/rtk-system-large.jpg`} alt="RTK系统" />
          <div className="product-intro">
            <h2>高精度定位解决方案</h2>
            <p>配备多频率GNSS信号的便携式RTK系统，支持蓝牙通讯技术，实现测量级精度定位。</p>
            <h2>RTK和GNSS</h2>
            <p>　RTK是一种利用GNSS载波相位观测值进行实时动态相对定位的技术，RTK的工作原理是基准站通过数据链将其观测值和测站坐标信息一起传送给流动站。流动站不仅通过数据链接收来自基准站的数据，还要采集GNSS观测数据，并在系统内组成差分观测值进行实时处理，同时给出厘米级定位结果。</p>
          </div>
        </section>

        <section className="product-features">
          <h2>系统特点</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>多频率GNSS接收</h3>
              <p>支持接收多频率GNSS信号，提供厘米级定位精度。</p>
            </div>
            <div className="feature-item">
              <h3>蓝牙通讯</h3>
              <p>采用先进的蓝牙通讯技术，确保数据传输的稳定性和实时性。</p>
            </div>
            <div className="feature-item">
              <h3>便携式设计</h3>
              <p>轻便的设备设计，适合现场施工环境使用。</p>
            </div>
          </div>
        </section>

        <section className="technical-specs">
          <h2>技术规格</h2>
          <div className="specs-content">
            <ul>
              <li>支持多频率GNSS信号接收</li>
              <li>蓝牙无线通讯技术</li>
              <li>厘米级定位精度</li>
              <li>适配iOS 16.0及以上系统</li>
              <li>支持4G/5G移动网络</li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default RTK; 