// ./src/hooks/useMapData.js
import { useEffect, useRef } from 'react';
import APIService from '../services/APIservice';

const useMapData = (map, pointClick) => {
    const markersRef = useRef([]); // 存储普通标记
    const linesRef = useRef([]);   // 存储线条

    useEffect(() => {
        const fetchData = async () => {
            try {
                const deviceData = await APIService.MapServer('filteredDevice');
                const pipelineData = await APIService.MapServer('filteredPipe');

                if (map) {
                    // 清除普通标记和线条
                    markersRef.current.forEach(marker => marker.setMap(null));
                    linesRef.current.forEach(line => line.setMap(null));

                    markersRef.current = [];
                    linesRef.current = [];

                    // 添加普通标记
                    deviceData.forEach(([test_name, lat, lon]) => {

                        const marker = new window.AMap.Marker({
                            position: [lon, lat],
                            map: map,
                            content: '<div style="background-color: yellow; width: 16px; height: 16px; border-radius: 50%;"></div>',
                        });

                        marker.on('click', () => {
                            const newGlbPath = `https://sitesight-test-1257543956.cos.ap-shanghai.myqcloud.com/models/${test_name}.glb`;
                            console.log('-------------------');
                            console.log(newGlbPath);
                            pointClick(newGlbPath);
                        });

                        markersRef.current.push(marker); // 存储普通标记
                    });

                    // 添加线条
                    pipelineData.forEach((line) => {
                        const lineCoords = JSON.parse(line);
                        const polyline = new window.AMap.Polyline({
                            path: lineCoords,
                            strokeColor: "#00A",
                            strokeWeight: 3,
                            map: map,
                        });

                        linesRef.current.push(polyline); // 存储线条
                    });
                }
            } catch (error) {
                console.error('Data fetching error:', error);
            }
        };

        if (map) {
            fetchData();
        }

        return () => {
            markersRef.current.forEach(marker => marker.setMap(null));
            linesRef.current.forEach(line => line.setMap(null));
        };
    }, [map, pointClick]);

};

export default useMapData;
