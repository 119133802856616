// ./src/components/SearchBar.js
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchBar.module.css';  // 引入样式

const SearchBar = ({ onSearch }) => {
    const [keyword, setKeyword] = useState('');

    const handleInputChange = (e) => {
        setKeyword(e.target.value);
    };

    const handleSearchClick = () => {
        if (keyword.trim()) {
            onSearch(keyword);
        } else {
            alert('请输入搜索关键词');
        }
    };

    return (
        <div className={styles.searchBarContainer}>
            <input
                type="text"
                value={keyword}
                onChange={handleInputChange}
                placeholder="输入地点名称"
                className={styles.searchInput}
            />
            <button onClick={handleSearchClick} className={styles.searchButton}>
                查询
            </button>
        </div>
    );
};

SearchBar.propTypes = {
    onSearch: PropTypes.func.isRequired,
};

export default SearchBar;
