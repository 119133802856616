// ./src/components/ThreeModelViewer.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './ThreeModelViewer.css';

const ThreeModelViewer = ({ glbPath }) => {
    const navigate = useNavigate();
    const [showInfo, setShowInfo] = useState(true);
    const [pipeInfo, setPipeInfo] = useState({});
    
    // 从glbPath中提取模型名称
    const getModelName = (path) => {
        console.log('Original path:', path);
        // 提取最后一个斜杠后的内容，并去掉.glb后缀
        const name = path.split('/').pop().replace('.glb', '');
        console.log('Extracted name:', name);
        return name;
    };

    useEffect(() => {
        const fetchModelInfo = async () => {
            try {
                const modelName = getModelName(glbPath);
                console.log('Fetching info for model:', modelName);
                
                const url = `/api/model/info/${modelName}`;
                console.log('Request URL:', url);
                
                const response = await fetch(url);
                console.log('Response status:', response.status);
                console.log('Response headers:', Object.fromEntries(response.headers));
                
                if (response.ok) {
                    const data = await response.json();
                    console.log('Received data:', data);
                    setPipeInfo(data);
                } else {
                    const errorData = await response.json();
                    console.error('Error response:', errorData);
                    console.error('Error status:', response.status);
                }
            } catch (error) {
                console.error('获取模型信息失败:', error);
                console.error('Error details:', {
                    message: error.message,
                    stack: error.stack
                });
            }
        };

        if (glbPath) {
            fetchModelInfo();
        }
    }, [glbPath]);

    const getInfoLabel = (key) => {
        const labels = {
            diameter: '管径',
            material: '材质',
            avgDepth: '平均深度',
            totalLength: '总长度',
            longitude: '经度',
            latitude: '纬度',
            buriedDepth: '拍摄高度',
            manager: '本段负责人',
            constructionDate: '施工日期'
        };
        return labels[key] || key;
    };

    return (
        <div className="model-container">
            <div className="model-view-area">
                <button
                    className="back-button"
                    onClick={() => navigate('/map')}
                >
                    返回地图
                </button>

                <model-viewer
                    src={glbPath}
                    alt="A 3D model"
                    camera-controls
                    enable-pan
                    touch-action="pan-z"
                    damping-factor="0.8"
                    camera-orbit="60deg 0deg 7.5m"
                    style={{ width: '100%', height: '100%' }}
                    material="doubleSided"
                >
                </model-viewer>
            </div>

            <div className="info-panel">
                <div 
                    className={`info-header ${showInfo ? 'active' : ''}`} 
                    onClick={() => setShowInfo(!showInfo)}
                >
                    <span className="info-dot"></span>
                    <h3 className="info-title">相关信息</h3>
                </div>

                {showInfo && (
                    <div className="info-content">
                        {Object.entries(pipeInfo).map(([key, value]) => (
                            <div key={key} className="info-row">
                                <span className="info-label">{getInfoLabel(key)}</span>
                                <span>{value}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ThreeModelViewer;
