// ./src/components/Popup.js
import React from 'react';

const Popup = ({ position, onClose }) => {
    const { x, y, lng, lat } = position;

    return (
        <div style={{
            position: 'absolute',
            top: y ? y + 10 : '50%', // 确保弹窗不会跑到左上角
            left: x ? x + 10 : '50%',
            backgroundColor: 'white',
            border: '1px solid black',
            borderRadius: '4px',
            padding: '10px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
            zIndex: 1000
        }}>
            <div>
                请注意：<br />
                经度: {lng} 纬度: {lat}<br />
                此处无模型，请点击黄色圆点<br />
            </div>
            <button
                onClick={onClose}
                style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    border: 'none',
                    background: 'transparent',
                    fontSize: '16px',
                    cursor: 'pointer'
                }}
            >
                &times;
            </button>
        </div>
    );
};

export default Popup;
