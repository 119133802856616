import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  const heroStyle = {
    background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${process.env.PUBLIC_URL}/images/hero-bg.jpg)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <div className="header-content">
          <div className="logo">
            <h1>巡智三维</h1>
          </div>
          <nav className="main-nav">
            <Link to="/about">关于我们</Link>
            <div className="dropdown">
              <span>产品功能</span>
              <div className="dropdown-content">
                <Link to="/products/rtk">RTK实时定位系统</Link>
                <Link to="/products/lidar">LiDAR扫描系统</Link>
                <Link to="/products/modeling">3D建模系统</Link>
              </div>
            </div>
            <Link to="/standards">技术标准</Link>
            <Link to="/features/support">技术支持</Link>
            <Link to="/features/security">安全保障</Link>
            <Link to="/contact">联系我们</Link>
          </nav>
          <div className="header-actions">
            <Link to="/login" className="login-link">登录</Link>
            <Link to="/login" className="try-free-btn">免费试用</Link>
          </div>
        </div>
      </header>

      <section className="hero-section" style={heroStyle}>
        <div className="hero-content">
          <h2>巡智三维</h2>
          <p>辅助施工软件, 为工程监管、项目验收和工作量测量提供全方位的数字化解决方案</p>
          <Link to="/login" className="learn-more-btn">了解更多</Link>
        </div>
      </section>

      <section className="products-grid">
        <div className="product-card">
          <div className="product-content">
            <img src={`${process.env.PUBLIC_URL}/images/rtk-system.jpg`} alt="RTK系统" />
            <h3>RTK实时定位系统</h3>
            <p>配备多频率GNSS信号的便携式RTK，支持蓝牙通讯，实现高精度定位和测量</p>
            <Link to="/products/rtk" className="product-link">了解更多</Link>
          </div>
        </div>

        <div className="product-card">
          <div className="product-content">
            <img src={`${process.env.PUBLIC_URL}/images/lidar-system.jpg`} alt="LiDAR系统" />
            <h3>LiDAR扫描系统</h3>
            <p>基于Apple最新LiDAR扫描器，实现精确的3D建模和空间数据采集</p>
            <Link to="/products/lidar" className="product-link">了解更多</Link>
          </div>
        </div>

        <div className="product-card">
          <div className="product-content">
            <img src={`${process.env.PUBLIC_URL}/images/modeling-system.jpg`} alt="3D建模系统" />
            <h3>3D建模系统</h3>
            <p>提供开挖深度测量、施工面积计算等核心功能，支持项目验收流程</p>
            <Link to="/products/modeling" className="product-link">了解更多</Link>
          </div>
        </div>
      </section>

      <section className="features-grid">
        <div className="feature-card">
          <img src={`${process.env.PUBLIC_URL}/images/monitoring.jpg`} alt="实时监控" />
          <h3>实时监控管理</h3>
          <p>实现施工交付的高效、线上标准化、透明化管理，提供全方位监控支持</p>
          <Link to="/features/monitoring" className="feature-link">查看详情</Link>
        </div>

        <div className="feature-card">
          <img src={`${process.env.PUBLIC_URL}/images/technical.jpg`} alt="技术支持" />
          <h3>专业技术支持</h3>
          <p>提供定期技术培训和评估，确保运维团队高效响应各类技术需求</p>
          <Link to="/features/support" className="feature-link">查看详情</Link>
        </div>

        <div className="feature-card">
          <img src={`${process.env.PUBLIC_URL}/images/security.jpg`} alt="安全保障" />
          <h3>安全保障体系</h3>
          <p>符合国家信息安全标准，提供多因素认证和完整的数据保护方案</p>
          <Link to="/features/security" className="feature-link">查看详情</Link>
        </div>
      </section>

      <footer className="home-footer">
        <p>© 2024 SiteSight 3D建模施工辅助软件. 保留所有权利</p>
      </footer>
    </div>
  );
};

export default Home; 