import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';

const About = () => {
  return (
    <div className="about-page">
      <header className="about-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>关于我们</h1>
      </header>

      <main className="about-content">
        <section className="company-intro">
          <h2>上海巡智科技有限公司</h2>
          <p>我们是一家专注于建筑施工信息化的技术公司，致力于为建筑行业提供智能化解决方案。</p>
        </section>

        <section className="product-intro">
          <h2>SiteSight 3D建模施工辅助软件</h2>
          <p>本软件适用于辅助工程监管、支持项目验收流程，并提供准确的工作量测量功能，如开挖深度和施工面积计算。</p>
          
          <div className="features-grid">
            <div className="feature">
              <h3>工程监管</h3>
              <p>提供实时监控和数据分析功能</p>
            </div>
            <div className="feature">
              <h3>项目验收</h3>
              <p>支持标准化的验收流程</p>
            </div>
            <div className="feature">
              <h3>工作量测量</h3>
              <p>精确计算施工面积和开挖深度</p>
            </div>
          </div>
        </section>

        <section className="standards-section">
          <h2>技术标准</h2>
          <p>遵循 Q/511421BRJ 002-2024 企业标准</p>
        </section>
      </main>
    </div>
  );
};

export default About; 