// ./src/hooks/useLoadMap.js

import { useEffect, useState, useRef } from 'react';

const useLoadMap = (setError) => {
    const [map, setMap] = useState(null);
    const scriptRef = useRef(null);  // 缓存script对象

    useEffect(() => {
        if (scriptRef.current) {
            setMap(new window.AMap.Map('container', { center: [121.468296, 30.916783], zoom: 16 }));
            return;
        }

        const loadMapScript = () => {
            const script = document.createElement('script');
            script.src = `https://webapi.amap.com/maps?v=1.4.15&key=a31e84a5f5d5a1d1c17038da59390737&plugin=AMap.Geocoder`;
            script.async = true;
            script.onload = () => {
                scriptRef.current = script;  // 缓存script对象
                if (window.AMap && window.AMap.Map) {
                    const mapInstance = new window.AMap.Map('container', { center: [121.468296, 30.916783], zoom: 16 });
                    setMap(mapInstance);
                } else {
                    setError(true);
                }
            };
            script.onerror = () => {
                setError(true);
            };
            document.body.appendChild(script);
        };

        loadMapScript();
    }, [setError]);

    return { map };
};

export default useLoadMap;
