import React from 'react';
import { Link } from 'react-router-dom';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <header className="contact-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>联系我们</h1>
      </header>

      <main className="contact-content">
        <section className="contact-info">
          <h2>联系方式</h2>
          <div className="info-grid">
            <div className="info-item">
              <h3>公司地址</h3>
              <p>上海市</p>
            </div>
            <div className="info-item">
              <h3>联系电话</h3>
              <p>400-XXX-XXXX</p>
            </div>
            <div className="info-item">
              <h3>电子邮箱</h3>
              <p>contact@sitesight.com</p>
            </div>
          </div>
        </section>

        <section className="support-hours">
          <h2>服务时间</h2>
          <p>周一至周五: 9:00 - 18:00</p>
          <p>技术支持: 7x24小时</p>
        </section>
      </main>
    </div>
  );
};

export default Contact; 