import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturePage.css';

const Support = () => {
  return (
    <div className="feature-page">
      <header className="feature-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>专业技术支持</h1>
      </header>

      <main className="feature-content">
        <section className="feature-hero">
          <img src={`${process.env.PUBLIC_URL}/images/support-large.jpg`} alt="技术支持" />
          <div className="feature-intro">
            <h2>全方位技术支持服务</h2>
            <p>提供专业的技术培训和运维支持，确保系统稳定运行</p>
          </div>
        </section>

        <section className="support-services">
          <h2>支持服务</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>技术培训</h3>
              <p>定期进行技术培训和评估，确保运维团队的专业水平</p>
            </div>
            <div className="feature-item">
              <h3>运维支持</h3>
              <p>提供7x24小时技术支持，快速响应各类技术问题</p>
            </div>
            <div className="feature-item">
              <h3>系统优化</h3>
              <p>定期进行系统维护和性能优化，确保最佳运行状态</p>
            </div>
          </div>
        </section>

        <section className="maintenance-content">
          <h2>维护内容</h2>
          <div className="maintenance-list">
            <ul>
              <li>日常管理维护</li>
              <li>软件维护更新</li>
              <li>数据备份维护</li>
              <li>运行环境维护</li>
              <li>安全漏洞修复</li>
              <li>性能调优服务</li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Support; 