// ./src/components/MapContainer.js
import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
// import MarkerButton from './MarkerButton';
import Popup from './Popup';
import SearchBar from './SearchBar';
import useLoadMap from '../hooks/useLoadMap';
import useMapData from '../hooks/useMapData';
import useMapClickHandler from '../hooks/useMapClickHandler';
import useSearchPlaces from '../hooks/useSearchPlaces';
import { useNavigate } from 'react-router-dom';
import styles from './MapContainer.module.css';

const MapContainer = ({ changeModelPath }) => {
    const [popupPosition, setPopupPosition] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const [mapError, setMapError] = useState(false);

    const navigate = useNavigate();
    const pointClick = (newGlbPath) => {
        changeModelPath(newGlbPath);
        navigate('/3d-model');
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupPosition(null);
    };

    const retryLoadMap = () => {
        setMapError(false);
    };

    const { map } = useLoadMap(setMapError);
    useMapData(map, pointClick);  // 普通数据加载
    useMapClickHandler(map, setPopupPosition, setShowPopup);

    const { searchPlace } = useSearchPlaces(map);  // 搜索功能

    return (
        <div id="container" className={styles.container}>
            {mapError ? (
                <div className={styles.error}>
                    <p>地图加载失败，请检查网络或API Key。</p>
                    <button onClick={retryLoadMap} className={styles.retryButton}>重试</button>
                </div>
            ) : (
                <>
                    <SearchBar onSearch={searchPlace} /> {/* 搜索功能 */}
                    {showPopup && popupPosition && (
                        <Popup position={popupPosition} onClose={handleClosePopup} />
                    )}
                </>
            )}
        </div>
    );
};

export default MapContainer;
