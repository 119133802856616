import React from 'react';
import { Link } from 'react-router-dom';
import './ProductPage.css';

const Modeling = () => {
  return (
    <div className="product-page">
      <header className="product-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>3D建模系统</h1>
      </header>

      <main className="product-content">
        <section className="product-hero">
          <img src={`${process.env.PUBLIC_URL}/images/modeling-system-large.jpg`} alt="3D建模系统" />
          <div className="product-intro">
            <h2>智能建模解决方案</h2>
            <p>提供开挖深度测量、施工面积计算等核心功能，支持项目验收全流程。</p>
          </div>
        </section>

        <section className="product-features">
          <h2>核心功能</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>深度测量</h3>
              <p>精确测量开挖深度，支持多点位比对分析。</p>
            </div>
            <div className="feature-item">
              <h3>面积计算</h3>
              <p>智能计算施工面积，支持复杂形状测量。</p>
            </div>
            <div className="feature-item">
              <h3>验收支持</h3>
              <p>提供完整的验收数据支持，确保施工质量。</p>
            </div>
          </div>
        </section>

        <section className="service-objects">
          <h2>服务对象</h2>
          <div className="objects-list">
            <ul>
              <li>施工管理者</li>
              <li>项目经理</li>
              <li>施工现场监理</li>
              <li>造价师</li>
              <li>安全官员</li>
              <li>质量检查员</li>
              <li>BIM专家</li>
            </ul>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Modeling; 