// ./src/services/APIservice.js
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export default class APIService {
    static async MapServer(type) {
        try {
            let response;
            switch (type) {
                case 'filteredDevice': // 过滤设备
                    response = await axios.get(`${API_URL}/map/get-models`);
                    break;
                case 'filteredPipe': // 过滤管线
                    response = await axios.get(`${API_URL}/map/get-pipes`);
                    break;
                default:
                    throw new Error('Unsupported request type');
            }
            return response.data; // 返回数据部分
        } catch (error) {
            console.error('Error fetching data from the server:', error);
            throw error; // 重新抛出错误，以便调用者处理
        }
    }
}
