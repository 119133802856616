import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturePage.css';

const Monitoring = () => {
  return (
    <div className="feature-page">
      <header className="feature-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>实时监控管理</h1>
      </header>

      <main className="feature-content">
        <section className="feature-hero">
          <img src={`${process.env.PUBLIC_URL}/images/monitoring-large.jpg`} alt="实时监控管理" />
          <div className="feature-intro">
            <h2>集中监控管理系统</h2>
            <p>实现服务对象施工交付的高效、线上标准化、透明化管理</p>
          </div>
        </section>

        <section className="monitoring-features">
          <h2>监控功能</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>实时监控</h3>
              <p>通过中央监控系统实时监控硬件状态、系统性能和运行日志</p>
            </div>
            <div className="feature-item">
              <h3>故障告警</h3>
              <p>及时侦测系统异常，通过预设告警机制立即通知运维人员</p>
            </div>
            <div className="feature-item">
              <h3>数据分析</h3>
              <p>对采集的实时信息进行数据合理性检查和异常数据分析</p>
            </div>
          </div>
        </section>

        <section className="monitoring-workflow">
          <h2>监控流程</h2>
          <div className="workflow-steps">
            <div className="step">
              <h3>1. 数据采集</h3>
              <p>实时采集设备运行状态和施工现场数据</p>
            </div>
            <div className="step">
              <h3>2. 数据处理</h3>
              <p>进行数据合理性检查和异常分析</p>
            </div>
            <div className="step">
              <h3>3. 预警响应</h3>
              <p>发现异常及时预警，确保及时处理</p>
            </div>
            <div className="step">
              <h3>4. 状态反馈</h3>
              <p>处理结果实时反馈，形成闭环管理</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Monitoring; 