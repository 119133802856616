// ./src/hooks/useMapClickHandler.js
import { useEffect } from 'react';

const useMapClickHandler = (map, setPopupPosition, setShowPopup) => {
    useEffect(() => {
        if (map) {
            const handleMapClick = (e) => {
                const { lng, lat } = e.lnglat;
                const { clientX, clientY } = e.originalEvent || window.event || {};
                setPopupPosition({ lng, lat, x: clientX, y: clientY });
                setShowPopup(true);
            };

            map.on('click', handleMapClick);

            // Cleanup on component unmount or when map changes
            return () => {
                map.off('click', handleMapClick);
                map.destroy();
            };
        }
    }, [map, setPopupPosition, setShowPopup]);
};

export default useMapClickHandler;
