import React from 'react';
import { Link } from 'react-router-dom';
import './FeaturePage.css';

const Security = () => {
  return (
    <div className="feature-page">
      <header className="feature-header">
        <Link to="/" className="back-link">返回首页</Link>
        <h1>安全保障体系</h1>
      </header>

      <main className="feature-content">
        <section className="feature-hero">
          <img src={`${process.env.PUBLIC_URL}/images/security-large.jpg`} alt="安全保障" />
          <div className="feature-intro">
            <h2>全面的安全保障方案</h2>
            <p>符合国家信息安全标准，提供完整的数据保护解决方案</p>
          </div>
        </section>

        <section className="security-aspects">
          <h2>安全体系</h2>
          <div className="features-list">
            <div className="feature-item">
              <h3>物理安全</h3>
              <p>包括环境安全、设备安全和信息媒体安全的全面保护</p>
            </div>
            <div className="feature-item">
              <h3>网络安全</h3>
              <p>实施安全域划分与隔离，建立完善的网络安全机制</p>
            </div>
            <div className="feature-item">
              <h3>软件安全</h3>
              <p>符合GB/T 22239和GB/T 28452的规定，确保应用安全</p>
            </div>
          </div>
        </section>

        <section className="security-measures">
          <h2>安全措施</h2>
          <div className="measures-list">
            <div className="measure-item">
              <h3>访问控制</h3>
              <p>实施统一的身份认证机制和精细化的权限控制</p>
            </div>
            <div className="measure-item">
              <h3>数据加密</h3>
              <p>采用SSL/TLS等安全协议，保护数据传输安全</p>
            </div>
            <div className="measure-item">
              <h3>安全审计</h3>
              <p>完整的操作日志记录和安全审计机制</p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Security; 